<template>
    <p class="consigne">{{ etape }} - Mettez le verbe entre « ne…pas ».</p>

    <div class="container">
    <span
      v-for="mot in listeSansVerbe"
      :key="mot"
    >
      {{ afficheAvecEspace(mot) }}
    </span>
  </div>

  <div
    v-for="(verbe, indice) in verbesSelectionnes"
    :key="indice"
    class="listeVerbes"
  >
    <span class="verbe"> {{ verbe }} </span>
    <input
      type="text"
      v-model="verbesValues[verbe]"
    />
  </div>

</template>

<script>
export default{
    props: ['etape'],

    emits: ['updateVerbesValuesNegation'],

    inject: ["verbesSelectionnes", "listeMots", "listeMotsRegroupes"],

    data(){
        return{
            verbesValues: {}
        }
    },

    watch:{
    verbesValues: {
      handler(newValue){
        this.$emit('updateVerbesValuesNegation', newValue)
      },
      deep: true // à utiliser quand on veut surveiller un objet, sinon on ne passe jamais dans le watch
    }
  },

    computed:{
        listeSansVerbe(){
            return this.listeMotsRegroupes.map((element) => (this.verbesSelectionnes.includes(element) ? this.verbesValues[element] || "_____"  : element));
        }
    },

    methods:{
        afficheAvecEspace(mot){
            if (mot === " ") { return "\u00a0" }
            else {return mot}
    }
}
}
</script>






<style scoped>
.container {
  max-width: 100rem;
  margin: 0rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: 12px;
}

.verbe {
  color: blue;
  margin-right: 1rem;
}

input {
  position: absolute;
  margin-left: 15rem;
}

.listeVerbes {
  display: flex;
  padding: 1px;
}
</style>