<template>
  <p class="consigne">1 - Sélectionnez un nom.</p>

  <p>
    Nom sélectionné : <span class="nom">{{ nomSelectionne }}</span>
  </p>

  <div v-if="etape >= 2">
    <hr class="hr-style"/>
    <p class="consigne">
      2 - Sélectionner le déterminant lié au nom.
      <span class="nom">{{ nomSelectionne }}</span>
    </p>
    Déterminant sélectionné :
    <span class="determinant">{{ determinantSelectionne }}</span>
  </div>

  <div v-if="etape === 3">
    <hr class="hr-style"/>
    <div class="form-control">
      <p class="consigne" :class="{ invalide: choix === 'en-attente' }">3 - Y a-t-il d'autres noms à analyser ?</p>
            <div>
                <input id="oui" name="choix" type="radio" value="oui"
                    v-model="choix" />
                <label for="oui">Oui</label>
            </div>
            <div>
                <input id="non" name="choix" type="radio" value="non"
                    v-model="choix" />
                <label for="non">Non</label>
            </div>
        </div>
  </div>

  <div class="button-container">
    <button
      class="annuler"
      @click="annulation"
      v-if="etape<=2"
    >
      Annuler
    </button>
    <button
      class="validation"
      @click="validation"
      v-if="etape<=2"
    >
      Valider
    </button>
    <button
      class="confirmer"
      @click="confirmer"
    >
      Suivant
    </button>
  </div>
</template>

<script>
export default {
    emits:["nomValidé", "nomAnnulé", "determinantValidé", "determinantAnnulé"],

  data() {
    return {
      nomSelectionne: "",
      determinantSelectionne: "",
      etape: 1,
      choix: null
    };
  },

  methods: {
    getHighlightedText() {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        return range.toString();
      }
    },

    validation() {
      if (this.etape === 1) {
        this.nomSelectionne = this.getHighlightedText();
        this.$emit("nomValidé", this.nomSelectionne);
      }
      else if (this.etape === 2) {
        this.determinantSelectionne = this.getHighlightedText();
        this.$emit("determinantValidé", this.determinantSelectionne);
    }
  },

    annulation() {
      if (this.etape === 1) {
        this.$emit("nomAnnulé", this.nomSelectionne);
        this.nomSelectionne = "";
      }
      else if (this.etape === 2) {
        this.$emit("determinantAnnulé", this.determinantSelectionne);
        this.determinantSelectionne = "";
      }
    },

    confirmer() {
      if (this.etape === 1) {
        this.etape = 2;
      } else if (this.etape === 2) {
        this.etape = 3;
      }
      else if (this.etape === 3) {
        if (this.choix === 'non'){
        this.$emit("fin-etape");}
        else if (this.choix === 'oui'){
          this.nomSelectionne = '';
          this.determinantSelectionne = '';
          this.choix = null;
          this.etape = 1;
        }
        else if (this.choix === null){
          this.choix = 'en-attente'
        }
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 100rem;
  margin: 0rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: 12px;
  margin-top: 10px;
}

hr {
  color: #333;
  background-color: #333;
  height: 2px;
}

.verbePrincipal {
  font-weight: bold;
}

.sujet {
  color: red;
}

.verbe {
  color: blue;
  text-decoration: underline;
}

.nom {
  color: rgb(155, 132, 5);
}

.determinant {
  color: darksalmon;
}

.entoure {
  border: 1px solid #000;
  /* Couleur de la bordure (noir dans cet exemple) et épaisseur de 1 pixel */
  border-radius: 10px;
  /* Ajoute des bords arrondis avec un rayon de 10 pixels (ajustez selon vos préférences) */
  padding: 5px;
  /* Ajoute un espace à l'intérieur de la bordure pour améliorer la lisibilité (facultatif) */
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

button.confirmer {
    font: inherit;
    border: 1px solid rgb(0, 0, 0);
    background-color: #770a41;
    color: white;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

button.confirmer:hover,
button.confirmer:active {
  border-color: rgb(0, 90, 27);
  background-color: #500029;
}

button.validation {
  font: inherit;
  border: 1px solid rgb(0, 90, 27);
  background-color: rgb(31, 187, 26);
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

button.validationVerbe:hover,
button.validationVerbe:active {
  border-color: rgb(0, 90, 27);
  background-color: rgb(11, 88, 9);
}

button.annuler {
  font: inherit;
  border: 1px solid #000000;
  background-color: #727071;
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

button.annuler:hover,
button.annuler:active {
  border-color: #000000;
  background-color: #353535;
}

button.pronom {
  box-shadow: 0px 1px 0px 0px #1c1b18;
  background: linear-gradient(to bottom, #eae0c2 5%, #ccc2a6 100%);
  background-color: #eae0c2;
  border-radius: 15px;
  border: 2px solid #333029;
  display: inline-block;
  cursor: pointer;
  color: #505739;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  padding: 12px 16px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
  margin-right: 20px;
}

button.pronom:hover {
  background: linear-gradient(to bottom, #ccc2a6 5%, #eae0c2 100%);
  background-color: #ccc2a6;
}

button.pronom:active {
  position: relative;
  top: 1px;
}

.motSelectionne {
  color: blue;
  text-decoration: underline;
}
</style>
