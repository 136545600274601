<template>
    <div v-if="open" @click="$emit('valider')"></div> <!-- fermer la boîte de dialogue quand on clique en dehors de la boîte -->
        <transition name="animationDialogue">
        <dialog open v-if="open">
            <header>
                <h2>Aide</h2>
            </header>
            <p>A compléter : ajouter des explications.</p>
            <menu>
                <button @click="valider">Valider</button>
            </menu>
            <!-- <button @click="confirmer">Ok</button> -->
        </dialog>
        </transition>
</template>

<script>
export default{
    emits:['valider'],

    props:['open'],

    methods:{
        valider(){
            this.$emit('valider');
        }
    }
}
</script>



<style scoped>
div {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 10;
}


dialog {
    position: fixed;
    top: 20vh;
    left: 10%;
    width: 80%;
    z-index: 100;
    border-radius: 12px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 0;
    margin: 0;
    overflow: hidden;
}

header {
    background-color: #640032;
    color: white;
    width: 100%;
    padding: 1rem;
}

header h2 {
    margin: 0;
}

section {
    padding: 1rem;
}

menu {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    margin: 0;
}

@media (min-width: 768px) {
    dialog {
        left: calc(50% - 20rem);
        width: 40rem;
    }
}

p {
    padding-left: 1rem;
}

button {
    font: inherit;
    border: 1px solid #ce0067;
    background-color: #ce0067;
    color: white;
    cursor: pointer;
    padding: 0.75rem 2rem;
    border-radius: 30px;
}

button:hover,
button:active {
    border-color: #77003c;
    background-color: #77003c;
}


.animationDialogue-enter-active {
    animation: fading 0.2s ease-out;
}

.animationDialogue-leave-active {
    animation: fading 0.2s ease-in reverse;
}

@keyframes fading {
    0% {
        opacity: 0;
        transform: translateY(-50px) scale(0.9);
    }

    100% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}
</style>