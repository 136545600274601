<template>
  <the-header @accueil="accueil"></the-header>
  <component :is="etape" @validationChoix="changementEtape" :="currentProps"></component>
</template>

<script>
import TheHeader from './components/UI/TheHeader.vue';
import TheChoices from './components/TheChoices.vue';
import FonctionsSyntaxiques from './components/FonctionsSyntaxiques.vue';
import ClassesGrammaticales from './components/ClassesGrammaticales.vue';

// import corpusData from './corpus.json';
const res = await fetch('/corpus.json')
const corpusData = await res.json()

export default {
  components:{
    TheHeader,
    TheChoices,
    FonctionsSyntaxiques,
    ClassesGrammaticales
  },

  computed:{
    currentProps(){ // calcule dynamiquement les props à passer à <component></component> selon l'étape où on est

      // Si on est à l'étape TheChoices, on ne passe aucune props
      if (this.etape === 'TheChoices'){
        return {corpusData: this.corpus}
      }

      // Sinon, on est à l'étape FonctionsSyntaxiques ou ClassesGrammaticales et dans ce cas on passe une phrase comme props
      else
      {
        const longueur = this.corpus[this.choixCorpus]["Phrases"].length;
        return {phrase: this.corpus[this.choixCorpus]["Phrases"][this.aleatoire(0, longueur-1)]}
      }
      }
  },

  data(){
    return {
      etape: 'TheChoices',

      corpus: corpusData, // tout le contenu des corpus par défaut est inclus dans le fichier corpus.json (qu'on importe plus haut)

      choixCorpus: null
    }
  },

    methods:{
      aleatoire(debut, fin){
        return debut + Math.floor(Math.random() * (fin - debut + 1));
      },

      changementEtape(corpus, analyse, phraseChoisie){
        this.choixCorpus = corpus;

        if (corpus === 'phrasePersonnalisee'){ // Si jamais l'utilisateur a choisi d'utiliser sa propre phrase, on ajoute la phrase qu'il a rentré dans la bonne clef du corpus
          this.corpus["phrasePersonnalisee"]["Phrases"].push(phraseChoisie);
        }

        this.etape = analyse === 'fonctions-syntaxiques' ? 'FonctionsSyntaxiques' : 'ClassesGrammaticales';
      },

      accueil(){
        this.etape='TheChoices';
      }
    }
  }
</script>





<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
}

.consigne {
  font-weight: bold;
}
</style>