<template>
  <p class="consigne">1 - Sélectionnez le COD.</p>

  <p>
    COD sélectionné : <span class="cod">{{ codSelectionne }}</span>
  </p>

  <div v-if="codSelectionne != ''" class="container">
    <p>
      C'est <span class="cod">{{ codSelectionne }}</span> que <span v-for="mot in listeSansCod" :key="mot">{{mot}}</span>
    </p>
  </div>

  <div v-if="etape === 2">
    <hr class="hr-style">
    <p class="consigne"> 2 - Choisissez le pronom qui convient pour remplacer <span class="cod">{{ codSelectionne }}</span>.</p>
    <div class="button-pronoms">
      <button class="pronom" v-for="pronom in listePronoms" :key="pronom" @click="choixPronom(pronom)">{{ pronom }}</button>
    </div>
  </div>

  <div v-if="etape=== 2" class="container">
    <p>
      <span
        v-for="(mot, indice) in listeSansCod"
        :key="indice"
      >
        {{ insererCodAvantVerbe(mot) }}
      </span>
    </p>
  </div>

  <div class="button-container">
    <button
      v-if="etape === 1"
      class="annuler"
      @click="annulationCod"
    >
      Annuler
    </button>
    <button
      v-if="etape === 1"
      class="validationCod"
      @click="validationCod"
    >
      Valider
    </button>
    <button
      class="confirmer"
      @click="confirmer"
    >
      Suivant
    </button>
  </div>
</template>

<script>
export default {
  emits: ["codAnnulé", "codValidé"],

  props: ['listeMots', 'verbePrincipal', 'sujetPrincipal'],

  data() {
    return {
      codSelectionne: "",
      etape: 1,
      listeCodRegroupe: [],
      listePronoms: ["Me", "M'", "Te", "T'", "Le", "L'", "La", "Nous", "Vous", "Les", "En"],
      pronomChoisi: "_____"
    };
  },

  computed:{
    listeSansCod(){
      return this.listeCodRegroupe.filter( (element) => element != this.codSelectionne);
    }
  },

  methods: {
    getHighlightedText() {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        return range.toString();
      }
    },

    annulationCod() {
      this.$emit("codAnnulé", this.codSelectionne);
      this.codSelectionne = "";
    },

    validationCod() {
      this.annulationCod();
      this.codSelectionne = this.getHighlightedText();
      this.listeCodRegroupe = this.regrouperCod(this.listeMots);
      this.$emit("codValidé", this.codSelectionne);
    },

    confirmer() {
      if (this.etape === 1) {
        this.etape = 2;
      }
      else if (this.etape === 2) {
        this.$emit('fin-etape', this.listeCodRegroupe);
      }
    },

    longueurCodSelectionne(chaine) {
      const nombreCod= chaine.split(" ").length;

      return nombreCod + (nombreCod - 1);
    },

    regrouperCod(liste) {
      let nouvelleListe = [...liste];

        // Séparer le sujet en mots
        let motsDuCod= this.codSelectionne.split(/\s+/);

        // Trouver l'index de la première occurrence des mots dans la nouvelle liste
        let index = nouvelleListe.findIndex((mot) => motsDuCod.includes(mot));

        // Si les mots sont trouvés dans la liste, les remplacer par le verbe complet
        if (index !== -1) {
          nouvelleListe.splice(
            index,
            this.longueurCodSelectionne(this.codSelectionne),
            this.codSelectionne
          );
        }

      return nouvelleListe;
    },

    choixPronom(pronom){
      this.pronomChoisi = pronom;
    },

    insererCodAvantVerbe(mot){
      /*
      Si le mot est le verbe principal, rajoute le pronom choisi associé au COD devant. Sinon, renvoie simplement le mot sans le changer.
      */
      if (mot === this.verbePrincipal){
        return this.pronomChoisi.toLowerCase() + ' ' + mot;
      }
      return mot
    }
  },
};
</script>

<style scoped>
.cod {
  color: green;
}

.container {
  max-width: 100rem;
  margin: 0rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: 12px;
  margin-top: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

button.confirmer {
    font: inherit;
    border: 1px solid rgb(0, 0, 0);
    background-color: #770a41;
    color: white;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

button.confirmer:hover,
button.confirmer:active {
  border-color: rgb(0, 90, 27);
  background-color: #500029;
}

button.validationCod {
  font: inherit;
  border: 1px solid rgb(0, 90, 27);
  background-color: rgb(31, 187, 26);
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

button.annuler {
  font: inherit;
  border: 1px solid #000000;
  background-color: #727071;
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

button.annuler:hover,
button.annuler:active {
  border-color: #000000;
  background-color: #353535;
}

button.pronom{
  box-shadow: 0px 1px 0px 0px #1c1b18;
	background:linear-gradient(to bottom, #eae0c2 5%, #ccc2a6 100%);
	background-color:#eae0c2;
	border-radius:15px;
	border:2px solid #333029;
	display:inline-block;
	cursor:pointer;
	color:#505739;
	font-family:Arial;
	font-size:14px;
	font-weight:bold;
	padding:12px 16px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
  margin-right: 20px;
}

button.pronom:hover{
	background:linear-gradient(to bottom, #ccc2a6 5%, #eae0c2 100%);
	background-color:#ccc2a6;  
}

button.pronom:active{
  position:relative;
	top:1px;
}

.hr-style {
    margin: 0; /* Supprime la marge par défaut du hr */
    border: none; /* Supprime la bordure par défaut du hr */
    border-top: 1px solid #ccc; /* Ajoute une bordure personnalisée */
    width: calc(100% - 5rem); /* Ajustez la largeur du hr en fonction de vos besoins */
}
</style>
