<template>
    <h2>Analyse des classes grammaticales</h2>


    <div
    v-if="etape === 1"
    class="container-secondaire"
  >
    <Etape-1
        @nomValidé="nomValide"
        @nomAnnulé="nomAnnule"
        @determinantValidé="determinantValide"
        @determinantAnnulé="determinantAnnule"
        @finEtape="incrementeEtape1"
    ></Etape-1>
  </div>

  <div
    v-if="etape === 2"
    class="container-secondaire"
  >
    <Etape-2
    @updateDeterminantsValues="updateDeterminantsValues"
    @finEtape="incrementeEtape2"
    ></Etape-2>
  </div>

  <div
    v-if="etape === 3"
    class="container-secondaire"
  >
  <RecapitulatifAnalyseCG>

  </RecapitulatifAnalyseCG>
  </div>
</template>

<script>
import Etape1 from "./Etape-1.vue";
import Etape2 from "./Etape-2.vue";
import RecapitulatifAnalyseCG from "./RecapitulatifAnalyseCG.vue";


export default {
    emits:["nomValidé", "nomAnnulé", "determinantValidé", "determinantAnnulé"],

    provide(){
        return{
            getDeterminantsValues: () => this.determinantsValues
        }
    },

    components: {
    Etape1,
    Etape2,
    RecapitulatifAnalyseCG
  },

  data() {
    return {
      etape: 1,
      determinantsValues: {}
    };
  },

  methods:{
    // Etape 1 - Nom
    nomValide(nomSelectionne) {
      this.$emit("nomValidé", nomSelectionne);
    },

    nomAnnule(nomSelectionne) {
      this.$emit("nomAnnulé", nomSelectionne);
    },

    determinantValide(determinantSelectionne) {
      this.$emit("determinantValidé", determinantSelectionne);
    },

    determinantAnnule(determinantSelectionne) {
      this.$emit("determinantAnnulé", determinantSelectionne);
    },

    incrementeEtape1() {
      this.etape += 1;
    },

    incrementeEtape2() {
      this.etape += 1;
    },

    updateDeterminantsValues(objet){
            this.determinantsValues = objet;
        },
    
  }

}
</script>


<style>
h2 {
  margin-left: 1rem;
}

.container-secondaire {
  max-width: 100rem;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  padding: 2rem;
  border: 2px solid #ccc;
  border-radius: 12px;
  position: relative;
}

button.confirmer {
    font: inherit;
    border: 1px solid rgb(0, 0, 0);
    background-color: #770a41;
    color: white;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

button.confirmer:hover,
button.confirmer:active {
  border-color: rgb(0, 90, 27);
  background-color: #500029;
}

.hr-style {
    margin: 0; /* Supprime la marge par défaut du hr */
    border-top: 1px solid #ccc; /* Ajoute une bordure personnalisée */
    width: calc(100% - 5rem); /* Ajustez la largeur du hr en fonction de vos besoins */
}

.invalide {
    color: red;
}
</style>