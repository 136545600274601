<template>
  <h1>Fonctions Syntaxiques</h1>

  <div
    class="container"
    v-if="etape1termine === false"
  >
    <p
      class="phrasePrincipale"
      @mouseup="getHighlightedText"
    >
      <span
        v-for="(mot, indice) in listeMots"
        :key="indice"
        :class="{
          motSelectionne: motSelectionne(mot, indice),
          sujet: estUnSujet(mot, indice),
        }"
      >
        {{ mot }}
      </span>
    </p>
  </div>

  <div
    class="container"
    v-if="etape1termine === true"
  >
    <p
      class="phrasePrincipale"
      @mouseup="getHighlightedText"
    >
      <span
        v-for="(mot, indice) in listeMotsVerbesRegroupes"
        :key="indice"
        :class="{
          motSelectionne: motSelectionneVerbesRegroupes(mot),
          verbePrincipal: estUnVerbePrincipal(mot),
          sujet: estUnSujet(mot, indice),
          cod: estUnCod(mot, indice),
          coi: estUnCoi(mot, indice),
          complement: estUnComplement(mot, indice),
          estCliquable: estCliquable(mot),
        }"
        @click="selectionVerbePrincipal(mot)"
      >
        {{ mot }}
      </span>
    </p>
  </div>

  <analyse-verbe
    @annuler="annulation"
    @annulerVerbePrincipal="annulationVerbePrincipal"
    @valider="validation"
    @confirmerEtape1="confirmationEtape1"
    @confirmerEtape2="confirmationEtape2"
    @finAnalyseVerbe="finAnalyseVerbe"
    v-if="partie === 'analyse-verbe'"
  >
    <template v-slot:selection-verbe>
      {{ selection }}
    </template>
  </analyse-verbe>

  <analyse-fonctions-syntaxiques
    v-if="partie === 'analyse-fonctions-syntaxiques'"
    @sujetValidé="sujetValide"
    @sujetAnnulé="sujetAnnule"
    @codValidé="codValide"
    @codAnnulé="codAnnule"
    @coiValidé="coiValide"
    @coiAnnulé="coiAnnule"
    @complémentValidé="complementValide"
    @complémentAnnulé="complementAnnule"
    :verbe-principal="verbePrincipal"
    :sujet-principal="sujetSelectionne[0]"
    :cod="codSelectionne[0]"
    :coi="coiSelectionne[0]"
    :complement="complementSelectionne"
    :liste-mots="listeMotsVerbesRegroupes"
  >
  </analyse-fonctions-syntaxiques>
</template>

<script>
import AnalyseVerbe from "./AnalyseVerbe/AnalyseVerbe.vue";
import AnalyseFonctionsSyntaxiques from "./AnalyseFonctionsSyntaxiques/AnalyseFonctionsSyntaxiques.vue";

export default {
  props: ["phrase"], // On reçoit la phrase en tant que prop à partir de App.vue

  provide() {
    return {
      verbesSelectionnes: this.selection,
      listeMots: this.listeMots,
      listeMotsRegroupes: this.listeMotsVerbesRegroupes,
      getVerbePrincipal: () => this.verbePrincipal,
      motSelectionneVerbesRegroupes: this.motSelectionneVerbesRegroupes,
    };
  },

  components: {
    AnalyseVerbe,
    AnalyseFonctionsSyntaxiques,
  },

  data() {
    return {
      selection: [],
      texteSelectionne: "",
      listeMotsVerbesRegroupes: [],
      partie: "analyse-verbe",
      sujetSelectionne: [],
      codSelectionne: [],
      coiSelectionne: [],
      complementSelectionne: [],
      verbePrincipal: "",
      etape1termine: false,
      etape2termine: false,
    };
  },

  computed: {
    listeMots() {
      // Retourne une liste avec les différents mots de la phrase, avec les apostrophes, espaces et points comment éléments de la liste mis à part. Utilise les regex.

      return this.phrase.split(/(\s+|'|’|\.|,)/).filter(Boolean); // le .filter(Boolean) permet d'enlever le caractère vide que rajoute split à la fin. Le \. est nécessaire car le simple . a une signification particulière en regex
    },
  },

  methods: {
    arrayEquals(a, b) {
      // Prend deux listes en entrées a et b et testent si elles ont la même longueur et contiennent les mêmes éléments aux mêmes emplacements
      // Utiliser "===" pour tester cela ne fonctionne pas en Javascript

      return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
      );
    },

    separe(groupe, mot) {
      // La fonction vérifie si le mot fait partie du groupe de mots et si c'est le cas, renvoie la liste (en cas de multiple occurences du mot) des listes des mots précédents et la liste des mots suivants dans le groupe

      // Vérifier si le mot est présent dans le groupe
      if (groupe.includes(mot)) {
        let mots = groupe.split(/(\s+|'|’|\.|,)/).filter(Boolean);
        let occurrences = [];

        for (let i = 0; i < mots.length; i++) {
          if (mots[i] === mot) {
            // Liste des mots avant le mot
            let avant = mots.slice(0, i);
            // Liste des mots après le mot
            let après = mots.slice(i + 1);
            occurrences.push([avant, après]);
          }
        }

        return occurrences;
      } else {
        return ["Le mot n'est pas présent dans le groupe.", ""];
      }
    },

    motSelectionne(mot, indice) {
      // On traite le cas d'un espace entre un verbe composé
      if (mot === " ") {
        for (let i = 0; i < this.selection.length; i++) {
          if (
            this.selection[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          ) {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot)) {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.selection.length; i++) {
        if (this.selection[i].split(/\s+/).includes(mot)) {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    motSelectionneVerbesRegroupes(mot) {
      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot)) {
        return false;
      }

      if (this.selection.includes(mot)) {
        return true;
      }

      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    sujetValide(sujetSelectionne) {
      this.sujetSelectionne.push(sujetSelectionne);
    },

    sujetAnnule(sujetSelectionne) {
      this.sujetSelectionne = this.sujetSelectionne.filter(
        (element) => element != sujetSelectionne
      );
    },

    estUnSujet(mot, indice) {
      // On traite le cas d'un espace entre un verbe composé
      if (mot === " ") {
        for (let i = 0; i < this.sujetSelectionne.length; i++) {
          if (
            this.sujetSelectionne[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          ) {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot)) {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.sujetSelectionne.length; i++) {
        if (this.sujetSelectionne[i].split(/\s+/).includes(mot)) {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    estUnVerbePrincipal(mot) {
      if (mot === this.verbePrincipal) {
        return true;
      }
      return false;
    },

    codValide(codSelectionne) {
      this.codSelectionne.push(codSelectionne);
    },

    codAnnule(codSelectionne) {
      this.codSelectionne = this.codSelectionne.filter(
        (element) => element != codSelectionne
      );
    },

    estUnCod(mot, indice) {
      // On traite le cas d'un espace entre un cod composé
      if (mot === " ") {
        for (let i = 0; i < this.codSelectionne.length; i++) {
          if (
            this.codSelectionne[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          ) {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot)) {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.codSelectionne.length; i++) {
        if (this.codSelectionne[i].split(/\s+/).includes(mot)) {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    coiValide(coiSelectionne) {
      this.coiSelectionne.push(coiSelectionne);
    },

    coiAnnule(coiSelectionne) {
      this.coiSelectionne = this.coiSelectionne.filter(
        (element) => element != coiSelectionne
      );
    },

    estUnCoi(mot, indice) {
      // On traite le cas d'un espace entre un cod composé
      if (mot === " ") {
        for (let i = 0; i < this.coiSelectionne.length; i++) {
          if (
            this.coiSelectionne[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          ) {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot)) {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.coiSelectionne.length; i++) {
        if (this.coiSelectionne[i].split(/\s+/).includes(mot)) {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    complementValide(texteSelectionne) {
      this.complementSelectionne.push(texteSelectionne);
    },

    complementAnnule() {
      this.complementSelectionne = [];
    },

    estUnComplement(mot, indice) {

      // On considère chaque complément circonstanciel (sous forme de chaine de caractères)
      for (let groupe of this.complementSelectionne) {

        // On parcourt chaque mot (y compris espace et autres) de la liste pour savoir s'il fait parti du groupe

        if (groupe.includes(mot)) {
          // Si jamais le mot fait parti de la chaîne de caractères du complément circonstanciel, il faut vérifier que ce qui l'entoure dans la phrase également

          // On commence par séparer la liste des mots qui précèdent dans le groupe, et qui suivent (il peut y avoir plusieurs occurences)
          let listes_total = this.separe(groupe, mot);

          for (let listes of listes_total) {
            let liste_avant_dans_groupe = listes[0];
            let liste_apres_dans_groupe = listes[1];

            let l1 = liste_avant_dans_groupe.length;
            let l2 = liste_apres_dans_groupe.length;

            let liste_avant_dans_phrase = this.listeMots.slice(
              Math.max(0, indice - l1),
              indice
            );
            let liste_apres_dans_phrase = this.listeMots.slice(
              indice + 1,
              Math.min(indice + 1 + l2, this.listeMots.length - 1)
            );

            if (
              this.arrayEquals(
                liste_avant_dans_groupe,
                liste_avant_dans_phrase
              ) &&
              this.arrayEquals(liste_apres_dans_groupe, liste_apres_dans_phrase)
            ) {
              return true;
            } else {
              return false;
            }
          }
        }

        return false;
      }
    },

    annulation() {
      this.selection = [];
    },

    annulationVerbePrincipal(){
      this.verbePrincipal = "";
    },

    validation() {
      this.selection.push(this.texteSelectionne);
    },

    confirmationEtape1() {
      this.listeMotsVerbesRegroupes.push(
        ...this.regrouperVerbes(this.listeMots)
      ); // on utilise cette méthode pour ne pas modifier le pointeur de la list, sinon provide&inject n'update pas (enfin je crois qu'il n'update jamais, mais du coup il faut envoyer un pointeur qui ne change pas, et ne pas réaffecter la liste)
      this.etape1termine = true;
    },

    confirmationEtape2() {
      this.etape2termine = true;
    },

    getHighlightedText() {
      var selection = null;

      if (window.getSelection) {
        selection = window.getSelection();
      } else if (typeof document.selection != "undefined") {
        selection = document.selection;
      }

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        this.texteSelectionne = range.toString();
      }
    },

    longueurVerbeSelectionne(chaine) {
      const nombreVerbes = chaine.split(" ").length;

      return nombreVerbes + (nombreVerbes - 1);
    },

    regrouperVerbes(liste) {
      let nouvelleListe = [...liste];
      for (const verbe of this.selection) {
        // Séparer le verbe en mots
        let motsDuVerbe = verbe.split(/\s+/);

        // Trouver l'index de la première occurrence des mots dans la nouvelle liste
        let index = nouvelleListe.findIndex((mot) => motsDuVerbe.includes(mot));

        // Si les mots sont trouvés dans la liste, les remplacer par le verbe complet
        if (index !== -1) {
          nouvelleListe.splice(
            index,
            this.longueurVerbeSelectionne(verbe),
            verbe
          );
        }
      }

      return nouvelleListe;
    },
    finAnalyseVerbe() {
      this.partie = "analyse-fonctions-syntaxiques";
    },

    estCliquable(mot) {
      // Les mots ne sont pas cliquables si l'étape 1 n'est pas terminé ou que l'étape 2 est terminé
      if (!this.etape1termine || this.etape2termine) {
        return false;
      }

      // Seuls les verbes sont cliquables
      if (this.selection.includes(mot)) {
        return true;
      }

      return false;
    },

    selectionVerbePrincipal(mot) {
      // Les mots ne sont pas interragibles si on est pas à l'étape 2 ou que le mot n'est pas un verbe
      if (
        !this.etape1termine ||
        this.etape2termine ||
        !this.selection.includes(mot)
      ) {
        return null;
      }

      this.verbePrincipal = mot;
    },
  },
};
</script>

<style scoped>
h1 {
  margin-left: 1rem;
}

p {
  margin-left: 1rem;
}

.verbePrincipal {
  font-weight: bold;
}

.sujet {
  color: red;
}

.cod {
  color: green;
}

.coi {
  color: rgb(151, 108, 13);
}

.complement {
  color: blueviolet;
}

.container {
  max-width: 100rem;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  border: 2px solid #ccc;
  border-radius: 12px;
}

.phrasePrincipale {
  font-size: 200%;
}

.motSelectionne {
  color: blue;
  text-decoration: underline;
}

.estCliquable {
  cursor: pointer;
}
</style>
