<template>
    <h2>Récapitulatif</h2>
  
    <div> <!-- Nom -->
    <h3>Nom</h3>

    Vous avez sélectionné les noms suivants :

    <ul>
      <li
        v-for="nom in getNomSelectionne()"
        :key="nom"
      >
      <span class="nom"> {{ nom }} </span>
      </li>
    </ul>
  </div>

  <div> <!-- Déterminant -->
    <h3>Déterminant</h3>

    <ul>
      <li
        v-for="(determinant, indice) in getDeterminantSelectionne()"
        :key="determinant"
      >
      Le déterminant associé au nom «<span class="nom">{{ getNomSelectionne()[indice] }}</span>» est «<span class="determinant">{{ determinant }}</span>».
      Il peut être remplacé par «<span class="determinant">{{ getDeterminantsValues()[determinant] }}</span>».
      </li>
    </ul>

    Remplacement : 
    
    <span
      v-for="mot in listeMotsRegroupes"
      :key="mot"
      
    >
      {{ motAffiche(mot) }}
    </span>
  </div>
</template>

<script>
export default{
    inject:["listeMotsRegroupes", "getNomSelectionne", "getDeterminantSelectionne", "getDeterminantsValues"],

    methods:{
        motAffiche(mot) {
      if (mot === " ") {
        return "\u00a0";
      } else if (this.getDeterminantSelectionne().includes(mot)) {
        return this.getDeterminantsValues()[mot] || "____"; // quand l'utilisateur n'a pas encore rempli le champ, la valeur de la clef est 'null'
      } else {
        return mot;
      }
    }
    }
}
</script>



<style scoped>


.determinant {
  color: darksalmon;
}

.nom {
  color: rgb(155, 132, 5);
}
</style>