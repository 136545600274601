<template>
    <p class="consigne">1 - Sélectionnez les compléments circonstanciels.</p>

    <p>Votre sélection : {{ selection }}</p>

    <div class="button-container">
            <button class="annuler" @click="annulation">Annuler</button>
            <button class="validationVerbe" @click="validation">Valider le verbe</button>
            <button class="confirmer" @click="confirmation">Suivant</button>
    </div>
</template>

<script>
export default{
    emits: ["complémentValidé", "complémentAnnulé", 'fin-etape'],

    data() {
    return {
      selection: [],
      texteSelectionne: "",
    };
  },

  methods: {
    annulation() {
        this.$emit("complémentAnnulé");
        this.texteSelectionne = '';
        this.selection = [];
    },

    validation() {
        this.texteSelectionne = this.getHighlightedText();
        this.selection.push(this.texteSelectionne);
        console.log('Complément : ', this.texteSelectionne);
        this.$emit("complémentValidé", this.texteSelectionne);
    },

    getHighlightedText() {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        return range.toString();
      }
    },

    confirmation(){
        this.$emit('fin-etape')
    }
  }
}
</script>


<style scoped>

.button-container {
    /* pour que les deux buttons soient côte a côte */
    display: flex;
    justify-content: center;
}

button.annuler {
    font: inherit;
    border: 1px solid #000000;
    background-color: #727071;
    color: white;
    cursor: pointer;
    padding: 0.75rem 2rem;
    border-radius: 30px;
    padding: 10px;
    margin-top: 10px;
    margin-right: 10px;
}

button.annuler:hover,
button.annuler:active {
    border-color: #000000;
    background-color: #353535;
}

button.confirmer {
    font: inherit;
    border: 1px solid rgb(0, 0, 0);
    background-color: #770a41;
    color: white;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

button.confirmer:hover,
button.confirmer:active {
    border-color: rgb(0, 90, 27);
    background-color: #500029;
}


button.validationVerbe {
    font: inherit;
    border: 1px solid rgb(0, 90, 27);
    background-color: rgb(31, 187, 26);
    color: white;
    cursor: pointer;
    padding: 0.75rem 2rem;
    border-radius: 30px;
    padding: 10px;
    margin-top: 10px;
    margin-right: 10px;
}

button.validationVerbe:hover,
button.validationVerbe:active {
    border-color: rgb(0, 90, 27);
    background-color: rgb(11, 88, 9);
}

</style>