<template>
  <header>
    <div class="icon-container" @click="accueil">
      <img
        src="../../../public/home.png"
        alt="Accueil"
      />
    </div>
    <div class="separator"></div>
    <h1 @click="accueil">GrammAnalyse</h1>
  </header>
</template>

<script>
export default{
  emits:['accueil'],

  methods:{
    accueil(){
      this.$emit('accueil')
    }
  }
}

</script>

<style scoped>
header {
  width: 100%;
  height: 5rem;
  background-color: #640032;
  display: flex;
  align-items: center;
}

header h1 {
  color: white;
  margin: 0;
  position: absolute; /* Ajoutez cette ligne pour positionner le titre de manière absolue */
  left: 50%; /* Centrez le titre horizontalement */
  transform: translateX(-50%); /* Centrez le titre horizontalement de manière précise */
  cursor: pointer;
}

.icon-container {
  display: flex;
  align-items: center; /* Alignez l'icône au centre verticalement */
  margin-right: 10px; /* Ajoutez une marge à droite pour séparer du trait séparateur */
  margin-left: 10px;
  cursor: pointer;
  opacity: 0.8;
}

.icon-container:hover {
  transform: scale(1.1); /* Ajustez la valeur selon votre préférence pour agrandir légèrement l'icône au survol */
  opacity: 1; /* Rend l'icône légèrement transparente */
}


img {
  width: auto; /* Utilisation d'une unité de mesure relative */
  height: 3rem;
}

.separator {
  height: 50%; /* Hauteur égale à la moitié de la hauteur du bandeau */
  width: 1px; /* Largeur du trait séparateur */
  background-color: #ccc; /* Couleur du trait séparateur */
  margin: 0 10px; /* Marge à gauche et à droite du trait séparateur */
}
</style>