<template>
    <h2>Récapitulatif</h2>
  
    <div> <!-- Sujet -->
      <h3>Sujet du verbe principal</h3>
  
      Le sujet du verbe principal «<span class="verbe-principal">{{ getVerbePrincipal() }}</span>» est «<span class="sujet">{{ getSujetPrincipal() }}</span>».
    </div>

    <div> <!-- COD -->
      <h3>Complément d'objet direct</h3>
  
      Le COD est «<span class="cod">{{ getCOD() }}</span>».
    </div>

    <div> <!-- COI -->
      <h3>Complément d'objet indirect</h3>
  
      Le COI est «<span class="coi">{{ getCOI() }}</span>».
    </div>

    <div> <!-- Compléments circonstanciels -->
      <h3>Compléments circonstanciels</h3>

      Les compléments circonstanciels sont les suivants :

      <ul>
      <li
        v-for="complement in getComplement()"
        :key="complement"
        class="complement"
      >
        {{ complement }}
      </li>
    </ul>
    </div>


  </template>
  
  <script>
  export default{
      inject: ['getSujetPrincipal', 'getVerbePrincipal', 'getCOD', 'getCOI', 'getComplement'],
  }
  </script>
  
  <style scoped>
  .verbe {
    color: blue;
  }

  .verbe-principal {
    color: blue;
    font-weight: bold;
  }

  .sujet {
  color: red;
}

.cod {
  color: green;
}

.coi {
  color: rgb(151, 108, 13);
}

.complement {
  color:blueviolet;
}
  
  label{
      float: left;
      clear: left;
      width: 60px;
      text-align: right;
      margin: 1rem;
  }
  input {
    float: left;
    margin: 1rem;
    width: 50%;
  }
  
  .souligne{
    text-decoration: underline;
  }
  </style>
  