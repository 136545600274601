<template>
    <p class="consigne">Remplacez le déterminant par un autre.</p>

    <div class="container">
    <span
      v-for="mot in listeMotsRegroupes"
      :key="mot"
    >
      {{ motAffiche(mot) }}
    </span>
  </div>

  <div
    v-for="(determinant, indice) in getDeterminantSelectionne()"
    :key="indice"
    class="listeDeterminants"
  >
    <span class="determinant"> {{ determinant }} </span>
    <input
      type="text"
      v-model="determinantsValues[determinant]"
    />
  </div>

  <div class="button-container">
    <button
      class="confirmer"
      @click="confirmer"
    >
      Suivant
    </button>
  </div>
</template>

<script>
export default{
    emits: ['updateDeterminantsValues'],

    inject: ["listeMotsRegroupes", "getDeterminantSelectionne"],

    data(){
        return{
            determinantsValues: {},
        }
    },

    watch:{
    determinantsValues: {
      handler(newValue){
        this.$emit('updateDeterminantsValues', newValue)
      },
      deep: true // à utiliser quand on veut surveiller un objet, sinon on ne passe jamais dans le watch
    }
  },

    methods:{
        motAffiche(mot) {
      if (mot === " ") {
        return "\u00a0";
      } else if (this.getDeterminantSelectionne().includes(mot)) {
        return this.determinantsValues[mot] || "____"; // quand l'utilisateur n'a pas encore rempli le champ, la valeur de la clef est 'null'
      } else {
        return mot;
      }
    },

    confirmer() {
        this.$emit("fin-etape");
    },
    }
}
</script>



<style scoped>
.container {
  max-width: 100rem;
  margin: 0rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: 12px;
}

.verbe {
  color: blue;
  margin-right: 1rem;
}

.determinant {
  color: darksalmon;
}

input {
  position: absolute;
  margin-left: 15rem;
}

.listeDeterminants {
  display: flex;
  padding: 1px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

button.validation {
  font: inherit;
  border: 1px solid rgb(0, 90, 27);
  background-color: rgb(31, 187, 26);
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

button.validationVerbe:hover,
button.validationVerbe:active {
  border-color: rgb(0, 90, 27);
  background-color: rgb(11, 88, 9);
}

button.annuler {
  font: inherit;
  border: 1px solid #000000;
  background-color: #727071;
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

button.annuler:hover,
button.annuler:active {
  border-color: #000000;
  background-color: #353535;
}
</style>