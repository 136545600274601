<template>
  <p class="consigne">{{ etape }} - Remplacez le(s) verbe(s) par un verbe de sens proche.</p>

  <div class="container">
    <span
      v-for="mot in listeMotsRegroupes"
      :key="mot"
    >
      {{ motAffiche(mot) }}
    </span>
  </div>

  <div
    v-for="(verbe, indice) in verbesSelectionnes"
    :key="indice"
    class="listeVerbes"
  >
    <span class="verbe"> {{ verbe }} </span>
    <input
      type="text"
      v-model="verbesValues[verbe]"
    />
  </div>
</template>

<script>
export default {
  props: ["etape"],

  emits: ['updateVerbesValues'],


  inject: ["verbesSelectionnes", "listeMots", "listeMotsRegroupes"],

  data() {
    return {
      verbesValues: {},
      nouvellePhrase: []
    };
  },

  watch:{
    verbesValues: {
      handler(newValue){
        this.$emit('updateVerbesValues', newValue)
      },
      deep: true // à utiliser quand on veut surveiller un objet, sinon on ne passe jamais dans le watch
    }
  },

  methods: {
    motAffiche(mot) {
      if (mot === " ") {
        return "\u00a0";
      } else if (this.verbesSelectionnes.includes(mot)) {
        return this.verbesValues[mot] || "____"; // quand l'utilisateur n'a pas encore rempli le champ, la valeur de la clef est 'null'
      } else {
        return mot;
      }
    },

    motDansListe(mot, liste) {
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < liste.length; i++) {
        if (liste[i].split(/\s+/).includes(mot)) {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    calculNouvellePhrase(){
      this.nouvellePhrase = this.listeMotsRegroupes.map(mot => this.motAffiche(mot));
    }
  },
};
</script>

<style scoped>
.container {
  max-width: 100rem;
  margin: 0rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: 12px;
}

.verbe {
  color: blue;
  margin-right: 1rem;
}

input {
  position: absolute;
  margin-left: 15rem;
}

.listeVerbes {
  display: flex;
  padding: 1px;
}
</style>
