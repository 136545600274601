<template>

    <form @submit.prevent="submitForm">

        <div class="settings">
            <button type="button" class="settings-button" @click="triggerBoite('aide')"><i
                    class="fa fa-question"></i></button>
            <button type="button" class="settings-button" @click="triggerBoite('parametres')"><i
                    class="fas fa-cog"></i></button>
        </div>


        <h2>Analyse grammaticale</h2>

        <div class="form-control">
            <label for="corpus" :class="{ invalide: choixValiditeCorpus === 'invalide' }">Choix du corpus</label>
            <select id="corpus" name="corpus" v-model="corpus">
                <option v-for="(corpusKey, i) in Object.keys(corpusData)" :key="i" :value="corpusKey"> {{corpusData[corpusKey].Titre }} </option>
            </select>
        </div>

        <div v-if="corpus === 'phrasePersonnalisee'">
            <label for="phraseInput" :class="{ invalide: choixValiditeCorpus === 'invalide' }">Entrez une phrase :</label>
            <input type="text" id="phraseInput" v-model="phraseChoisie" />
        </div>


        <div class="form-control">
            <label :class="{ invalide: choixValiditeAnalyse === 'invalide' }">Choix de l'analyse</label>
            <div>
                <input id="fonctions-syntaxiques" name="analyse" type="radio" value="fonctions-syntaxiques"
                    v-model="analyse" />
                <label for="fonctions-syntaxiques">Fonctions syntaxiques</label>
            </div>
            <div>
                <input id="classes-grammaticales" name="analyse" type="radio" value="classes-grammaticales"
                    v-model="analyse" />
                <label for="classes-grammaticales">Classes grammaticales</label>
            </div>
        </div>

        <div>
            <button>Valider</button>
        </div>
    </form>

    <p v-if="choixValiditeCorpus === 'valide' && choixValiditeAnalyse === 'valide'">Merci pour vos choix !</p>
    <p v-else>Choisissez les options.</p>

    <teleport to="body">
        <!-- <the-help v-if="boite_aide" @valider="aideValider"></the-help>
        <the-parameters v-if="boite_parametres" @valider="parametresValider"></the-parameters> -->
        <the-help :open="boite_aide" @valider="aideValider"></the-help>
        <the-parameters :open="boite_parametres" @valider="parametresValider"></the-parameters>
    </teleport>
</template>


<script>
import TheParameters from './Boites/TheParameters.vue';
import TheHelp from './Boites/TheHelp.vue';

export default{
    emits: ['validationChoix'],

    props: ['corpusData'],

    components:{
        TheParameters,
        TheHelp
    },

    data(){
        return{
            corpus: null, // pour le 1er choix
            analyse: null, // pour le 2nd choix
            choixValiditeCorpus: 'en_attente', // est-ce que les choix ont bien été effectués?
            choixValiditeAnalyse: 'en_attente',
            phraseChoisie: '',
            boite_parametres: false,
            boite_aide: false
        }
    },

    methods:{
        submitForm(){
            // On vérifique que les paramètres ont été choisis
            this.choixValiditeCorpus = (this.corpus === null) || (this.corpus === 'phrasePersonnalisee' && this.phraseChoisie === '') ? 'invalide' : 'valide'; 
            this.choixValiditeAnalyse = this.analyse === null ? 'invalide' : 'valide';

            // On transmet les informations à App.vue si les informations ont été fournies
            if (this.choixValiditeCorpus === 'valide' && this.choixValiditeAnalyse === 'valide')
            {
            this.$emit('validationChoix', this.corpus, this.analyse, this.phraseChoisie);
            }
        },

        triggerBoite(type){
            if (type === 'aide') {this.boite_aide = true}
            else if (type === 'parametres') {this.boite_parametres = true}
        },

        aideValider(){
            this.boite_aide = false;
        },

        parametresValider(){
            this.boite_parametres = false;
        }
    }


};
</script>



<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

form {
    margin: 2rem auto;
    max-width: 40rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 2rem;
    background-color: #ffffff;
}

h2 {
  margin-left: 0rem;
}

.form-control {
    margin: 2rem 0;
}

/* .form-control.invalide select {
    border-color: red;
} */

.invalide {
    color: red;
}

label {
    font-weight: bold;
}

input,
select {
    display: block;
    width: 100%;
    font: inherit;
    margin-top: 0.5rem;
}

input[type='radio'] {
    display: inline-block;
    width: auto;
    margin-right: 1rem;
}

input[type='radio']+label {
    font-weight: normal;
}

button {
    font: inherit;
    border: 1px solid #ce0067;
    background-color: #ce0067;
    color: white;
    cursor: pointer;
    padding: 0.75rem 2rem;
    border-radius: 30px;
}

button:hover,
button:active {
    border-color: #77003c;
    background-color: #77003c;
}

p {
    text-align: center;
}

.settings {
    float: right;
}

.settings-button {
    position: relative;
    padding: 5px 10px;
    background-color: #5f6366;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 1rem;
    /* Espacement entre les boutons si nécessaire */
}
</style>