<template>
    <h2>Analyse du verbe</h2>

    <div class="container-secondaire">
        <Etape-1 v-if="etape===1" :etape="etape">
                <template v-slot:selection-verbe>
                    <slot name="selection-verbe"></slot>
                </template>
        </Etape-1>

        <Etape-2 v-if="etape===2" :etape="etape">
        
        </Etape-2>

        <Etape-3 v-if="etape===3" :etape="etape" @updateVerbesValues="updateVerbesValues">
        
        </Etape-3>

        <Etape-4 v-if="etape===4" :etape="etape" @updateVerbesValuesNegation="updateVerbesValuesNegation">
        
        </Etape-4>

        <Etape-5 v-if="etape===5" :etape="etape" @updatePhraseHier="updatePhraseHier" @updatePhraseAuj="updatePhraseAuj" @updatePhraseDemain="updatePhraseDemain">
        
        </Etape-5>

        <LeRecapitulatif v-if="etape===6">

        </LeRecapitulatif>


        <div v-if="etape<=6" class="button-container">
            <button v-if="etape<=2" class="annuler" @click="annuler">Annuler</button>
            <button v-if="etape===1" class="validationVerbe" @click="valider">Valider le verbe</button>
            <button class="confirmer" @click="confirmer">Suivant</button>
        </div>

    </div>
</template>


<script>
import Etape1 from './Etape-1.vue'
import Etape2 from './Etape-2.vue'
import Etape3 from './Etape-3.vue'
import Etape4 from './Etape-4.vue'
import Etape5 from './Etape-5.vue'
import LeRecapitulatif from './LeRecapitulatif.vue'

export default{
    emits:['annuler', 'valider', 'confirmerEtape1', 'confirmerEtape2', 'finAnalyseVerbe', 'annuler-verbe-principal'],

    provide(){
        return{
            getVerbesValues: () => this.verbesValues, // si on passe directement l'objet, il n'est pas maj (voir : https://stackoverflow.com/questions/60416153/making-vue-js-provide-inject-reactive)
            getVerbesValuesNegation: () => this.verbeValuesNegation,
            getPhraseHier: () => this.phraseHier,
            getPhraseAuj: () => this.phraseAuj,
            getPhraseDemain: () => this.phraseDemain
        }
    },

    components:{
        Etape1,
        Etape2,
        Etape3,
        Etape4,
        Etape5,
        LeRecapitulatif
    },

    data(){
        return{
            etape: 1,
            verbesValues: {},
            verbeValuesNegation: {},
            phraseHier: "",
            phraseAuj: "",
            phraseDemain: ""
        }
    },

    methods:{
        annuler(){
            if (this.etape === 2){
                this.$emit('annuler-verbe-principal')
            }
            else {
            this.$emit('annuler')
            }
        },

        valider(){
            this.$emit('valider');
        },

        confirmer(){
            if (this.etape === 1){
            this.$emit('confirmerEtape1');
            }
            if (this.etape === 2){
            this.$emit('confirmerEtape2');
            }
            if (this.etape === 6){
            this.$emit('finAnalyseVerbe');
            }

            this.etape += 1;
        },

        updateVerbesValues(objet){
            this.verbesValues = objet;
        },

        updateVerbesValuesNegation(objet){
            this.verbeValuesNegation = objet;
        },

        updatePhraseHier(phrase){
            this.phraseHier = "Hier, " + phrase
        },

        updatePhraseAuj(phrase){
            this.phraseAuj = "Aujourd'hui, " + phrase
        },

        updatePhraseDemain(phrase){
            this.phraseDemain = "Demain, " + phrase
        }
    }
}
</script>






<style scoped>
h2 {
    margin-left: 1rem;
}

.container-secondaire {
    max-width: 100rem;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    padding: 2rem;
    border: 2px solid #ccc;
    border-radius: 12px;
    position: relative;
}

li {
    font-weight: bold;
}


.button-container {
    /* pour que les deux buttons soient côte a côte */
    display: flex;
    justify-content: center;
}

button.confirmer {
    font: inherit;
    border: 1px solid rgb(0, 0, 0);
    background-color: #770a41;
    color: white;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}


button.confirmer:hover,
button.confirmer:active {
    border-color: rgb(0, 90, 27);
    background-color: #500029;
}


button.validationVerbe {
    font: inherit;
    border: 1px solid rgb(0, 90, 27);
    background-color: rgb(31, 187, 26);
    color: white;
    cursor: pointer;
    padding: 0.75rem 2rem;
    border-radius: 30px;
    padding: 10px;
    margin-top: 10px;
    margin-right: 10px;
}

button.validationVerbe:hover,
button.validationVerbe:active {
    border-color: rgb(0, 90, 27);
    background-color: rgb(11, 88, 9);
}



button.annuler {
    font: inherit;
    border: 1px solid #000000;
    background-color: #727071;
    color: white;
    cursor: pointer;
    padding: 0.75rem 2rem;
    border-radius: 30px;
    padding: 10px;
    margin-top: 10px;
    margin-right: 10px;
}

button.annuler:hover,
button.annuler:active {
    border-color: #000000;
    background-color: #353535;
}
</style>