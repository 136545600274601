<template>
  <h1>Classes Grammaticales</h1>

  <div
    class="container"
    v-if="etape1termine === false"
  >
    <p
      class="phrasePrincipale"
      @mouseup="getHighlightedText"
    >
      <span
        v-for="(mot, indice) in listeMots"
        :key="indice"
        :class="{
          motSelectionne: motSelectionne(mot, indice),
        }"
      >
        {{ mot }}
      </span>
    </p>
  </div>

  <div
    class="container"
    v-if="etape1termine === true"
  >
    <p
      class="phrasePrincipale"
      @mouseup="getHighlightedText"
    >
      <span
        v-for="(mot, indice) in listeMotsVerbesRegroupes"
        :key="indice"
        :class="{
          motSelectionne: motSelectionneVerbesRegroupes(mot),
          verbePrincipal: estUnVerbePrincipal(mot),
          estCliquable: estCliquable(mot),
          nom: estUnNom(mot, indice),
          determinant: estUnDeterminant(mot, indice),
        }"
        @click="selectionVerbePrincipal(mot)"
      >
        {{ mot }}
      </span>
    </p>
  </div>

  <analyse-verbe
    @annuler="annulation"
    @annulerVerbePrincipal="annulationVerbePrincipal"
    @valider="validation"
    @confirmerEtape1="confirmationEtape1"
    @confirmerEtape2="confirmationEtape2"
    @finAnalyseVerbe="finAnalyseVerbe"
    v-if="partie === 'analyse-verbe'"
  >
    <template v-slot:selection-verbe>
      {{ selection }}
    </template>
  </analyse-verbe>

  <analyse-classes-grammaticales
    @nomValidé="nomValide"
    @nomAnnulé="nomAnnule"
    @determinantValidé="determinantValide"
    @determinantAnnulé="determinantAnnule"
    v-if="partie === 'analyse-classes-grammaticales'"
  >
  </analyse-classes-grammaticales>
</template>

<script>
import AnalyseVerbe from "./AnalyseVerbe/AnalyseVerbe.vue";
import AnalyseClassesGrammaticales from "./AnalyseClassesGrammaticales/AnalyseClassesGrammaticales.vue";

export default {
  props: ["phrase"], // On reçoit la phrase en tant que prop à partir de App.vue

  components: {
    AnalyseVerbe,
    AnalyseClassesGrammaticales,
  },

  provide() {
    return {
      verbesSelectionnes: this.selection,
      listeMots: this.listeMots,
      listeMotsRegroupes: this.listeMotsVerbesRegroupes,
      getVerbePrincipal: () => this.verbePrincipal,
      motSelectionneVerbesRegroupes: this.motSelectionneVerbesRegroupes,
      getNomSelectionne: () => this.nomSelectionne,
      getDeterminantSelectionne: () => this.determinantSelectionne
    };
  },

  data() {
    return {
      selection: [],
      texteSelectionne: "",
      listeMotsVerbesRegroupes: [],
      verbePrincipal: "",
      partie: "analyse-verbe",
      nomSelectionne: [],
      determinantSelectionne: [],
      etape1termine: false,
      etape2termine: false,
    };
  },

  computed: {
    // listeMots() {
    //   const mots = this.phrase.split(" ");
    //   let liste = [];

    //   mots.forEach((mot) => {
    //     liste = liste.concat(mot.match(/[^,.]+|[,.]/g) || []);
    //   });

    //   let listeAvecEspaces = [];
    //   let i = 0;

    //   while (i < liste.length) {
    //     listeAvecEspaces.push(liste[i]);

    //     // Vérifie si le caractère suivant n'est ni une virgule ni un point
    //     if (
    //       i + 1 < liste.length &&
    //       liste[i + 1] !== "," &&
    //       liste[i + 1] !== "."
    //     ) {
    //       listeAvecEspaces.push(" ");
    //     }

    //     i++;
    //   }

    //   return listeAvecEspaces;
    // },

    listeMots() {
      // Retourne une liste avec les différents mots de la phrase, avec les apostrophes, espaces et points comment éléments de la liste mis à part. Utilise les regex.

      return this.phrase.split(/(\s+|'|’|\.|,)/).filter(Boolean) // le .filter(Boolean) permet d'enlever le caractère vide que rajoute split à la fin. Le \. est nécessaire car le simple . a une signification particulière en regex
    }
  },

  methods: {
    getHighlightedText() {
      var selection = null;

      if (window.getSelection) {
        selection = window.getSelection();
      } else if (typeof document.selection != "undefined") {
        selection = document.selection;
      }

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        this.texteSelectionne = range.toString();
      }
    },

    annulation() {
      this.selection = [];
    },

    annulationVerbePrincipal(){
      this.verbePrincipal = "";
    },

    validation() {
      this.selection.push(this.texteSelectionne);
    },

    confirmationEtape1() {
      this.listeMotsVerbesRegroupes.push(
        ...this.regrouperVerbes(this.listeMots)
      ); // on utilise cette méthode pour ne pas modifier le pointeur de la list, sinon provide&inject n'update pas (enfin je crois qu'il n'update jamais, mais du coup il faut envoyer un pointeur qui ne change pas, et ne pas réaffecter la liste)
      this.etape1termine = true;
    },

    confirmationEtape2() {
      this.etape2termine = true;
    },

    finAnalyseVerbe() {
      this.partie = "analyse-classes-grammaticales";
    },

    motSelectionne(mot, indice) {
      // On traite le cas d'un espace entre un verbe composé
      if (mot === " ") {
        for (let i = 0; i < this.selection.length; i++) {
          if (
            this.selection[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          ) {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot)) {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.selection.length; i++) {
        if (this.selection[i].split(/\s+/).includes(mot)) {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    longueurVerbeSelectionne(chaine) {
      const nombreVerbes = chaine.split(" ").length;

      return nombreVerbes + (nombreVerbes - 1);
    },

    regrouperVerbes(liste) {
      let nouvelleListe = [...liste];
      for (const verbe of this.selection) {
        // Séparer le verbe en mots
        let motsDuVerbe = verbe.split(/\s+/);

        // Trouver l'index de la première occurrence des mots dans la nouvelle liste
        let index = nouvelleListe.findIndex((mot) => motsDuVerbe.includes(mot));

        // Si les mots sont trouvés dans la liste, les remplacer par le verbe complet
        if (index !== -1) {
          nouvelleListe.splice(
            index,
            this.longueurVerbeSelectionne(verbe),
            verbe
          );
        }
      }

      return nouvelleListe;
    },

    motSelectionneVerbesRegroupes(mot) {
      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot)) {
        return false;
      }

      if (this.selection.includes(mot)) {
        return true;
      }

      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    estUnVerbePrincipal(mot) {
      if (mot === this.verbePrincipal) {
        return true;
      }
      return false;
    },

    estCliquable(mot) {
      // Les mots ne sont pas cliquables si l'étape 1 n'est pas terminé ou que l'étape 2 est terminé
      if (!this.etape1termine || this.etape2termine) {
        return false;
      }

      // Seuls les verbes sont cliquables
      if (this.selection.includes(mot)) {
        return true;
      }

      return false;
    },

    selectionVerbePrincipal(mot) {
      // Les mots ne sont pas interragibles si on est pas à l'étape 2 ou que le mot n'est pas un verbe
      if (
        !this.etape1termine ||
        this.etape2termine ||
        !this.selection.includes(mot)
      ) {
        return null;
      }

      this.verbePrincipal = mot;
    },

    estUnNom(mot, indice) {
      // On traite le cas d'un espace entre un nom composé
      if (mot === " ") {
        for (let i = 0; i < this.nomSelectionne.length; i++) {
          if (
            this.nomSelectionne[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          ) {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot)) {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.nomSelectionne.length; i++) {
        if (this.nomSelectionne[i].split(/\s+/).includes(mot)) {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },

    nomValide(nomSelectionne) {
      this.nomSelectionne.push(nomSelectionne);
    },

    nomAnnule(nomSelectionne) {
      console.log(nomSelectionne);
      this.nomSelectionne = this.nomSelectionne.filter(
        (element) => element != nomSelectionne
      );
    },

    determinantValide(determinantSelectionne) {
      this.determinantSelectionne.push(determinantSelectionne);
    },

    determinantAnnule(determinantSelectionne) {
      console.log(determinantSelectionne);
      this.determinantSelectionne = this.determinantSelectionne.filter(
        (element) => element != determinantSelectionne
      );
    },

    estUnDeterminant(mot, indice) {
      // On traite le cas d'un espace entre un determinant composé
      if (mot === " ") {
        for (let i = 0; i < this.determinantSelectionne.length; i++) {
          if (
            this.determinantSelectionne[i].includes(
              this.listeMots[indice - 1] + " " + this.listeMots[indice + 1]
            )
          ) {
            return true;
          }
        }
      }

      // On retire les mots qu'on ne veut pas traiter
      if ([",", ".", ",", " "].includes(mot)) {
        return false;
      }
      // Parcourir chaque phrase dans la liste
      for (let i = 0; i < this.determinantSelectionne.length; i++) {
        if (this.determinantSelectionne[i].split(/\s+/).includes(mot)) {
          return true; // Si le mot est trouvé, retourner true
        }
      }
      // Si le mot n'est trouvé dans aucune phrase, retourner false
      return false;
    },
  },
};
</script>

<style scoped>
h1 {
  margin-left: 1rem;
}

p {
  margin-left: 1rem;
}

.verbePrincipal {
  font-weight: bold;
}

.sujet {
  color: red;
}

.cod {
  color: green;
}

.coi {
  color: rgb(151, 108, 13);
}

.nom {
  color: rgb(155, 132, 5);
  display: inline-block; /* Assure que le cercle n'occupe que l'espace nécessaire autour du texte */
  padding: 0.5em; /* Ajoutez un espace intérieur pour dégager le texte du cercle */
  border: 2px solid rgb(155, 132, 5); /* Couleur de la bordure */
  border-radius: 50%; /* Border-radius à 50% crée un cercle */
}

.determinant {
  color: darksalmon;
  text-decoration: underline;
}

.container {
  max-width: 100rem;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  border: 2px solid #ccc;
  border-radius: 12px;
}

.phrasePrincipale {
  font-size: 200%;
}

.motSelectionne {
  color: blue;
  text-decoration: underline;
}

.estCliquable {
  cursor: pointer;
}
</style>
