<template>
    <p class="consigne">{{ etape }} - Cliquez sur le verbe principal.</p>
    
    Votre sélection : {{ getVerbePrincipal() }}
</template>


<script>
export default{
    props:['etape', 'listeMotsVerbesRegroupes'],

    inject: ["getVerbePrincipal"]
}
</script>