<template>
  <h2>Analyse des fonctions syntaxiques</h2>

  <div
    v-if="etape === 1"
    class="container-secondaire"
  >
    <Etape-1
      @sujetValidé="sujetValide"
      @sujetAnnulé="sujetAnnule"
      @finEtape="incrementeEtape1"
      :verbe-principal="verbePrincipal"
      :liste-mots="listeMots"
    ></Etape-1>
  </div>

  <div
    v-if="etape === 2"
    class="container-secondaire"
  >
    <Etape-2
      @codValidé="codValide"
      @codAnnulé="codAnnule"
      :liste-mots="listeSujetRegroupe"
      :verbe-principal="verbePrincipal"
      :sujet-principal="sujetPrincipal"
      @finEtape="incrementeEtape2"
    ></Etape-2>
  </div>

  <div
    v-if="etape === 3"
    class="container-secondaire"
  >
    <Etape-3
      @coiValidé="coiValide"
      @coiAnnulé="coiAnnule"
      :liste-mots="listeSujetRegroupe"
      :verbe-principal="verbePrincipal"
      :sujet-principal="sujetPrincipal"
      @finEtape="incrementeEtape3"
    ></Etape-3>
  </div>

  <div
    v-if="etape === 4"
    class="container-secondaire"
  >
    <Etape-4
      @complémentValidé="complementValide"
      @complémentAnnulé="complementAnnule"
      @finEtape="incrementeEtape4"
    ></Etape-4>
  </div>

  <div
    v-if="etape === 5"
    class="container-secondaire"
  >
    <RecapitulatifAnalyseFS> </RecapitulatifAnalyseFS>
  </div>
</template>

<script>
import Etape1 from "./Etape-1.vue";
import Etape2 from "./Etape-2.vue";
import Etape3 from "./Etape-3.vue";
import Etape4 from "./Etape-4.vue";
import RecapitulatifAnalyseFS from "./RecapitulatifAnalyseFS.vue";

export default {
  emits: [
    "sujetValidé",
    "sujetAnnulé",
    "codValidé",
    "codAnnulé",
    "coiValidé",
    "coiAnnulé",
    "complémentValidé",
    "complémentAnnulé",
  ],

  props: ["verbePrincipal", "sujetPrincipal", "liste-mots", "cod", "coi", "complement"],

  components: {
    Etape1,
    Etape2,
    Etape3,
    Etape4,
    RecapitulatifAnalyseFS,
  },

  provide() {
    return {
      getVerbePrincipal: () => this.verbePrincipal,
      getSujetPrincipal: () => this.sujetPrincipal,
      getCOD: () => this.cod,
      getCOI: () => this.coi,
      getComplement: () => this.complement
    };
  },

  data() {
    return {
      etape: 1,
      listeSujetRegroupe: [],
      listeCodRegroupe: [],
    };
  },

  methods: {
    // Etape 1 - Sujet
    sujetValide(sujetSelectionne) {
      this.$emit("sujetValidé", sujetSelectionne);
    },

    sujetAnnule(sujetSelectionne) {
      this.$emit("sujetAnnulé", sujetSelectionne);
    },

    incrementeEtape1(listeSujetRegroupe) {
      this.listeSujetRegroupe = listeSujetRegroupe;
      this.etape += 1;
    },

    // Etape 2 - Cod
    codValide(codSelectionne) {
      this.$emit("codValidé", codSelectionne);
    },

    codAnnule(codSelectionne) {
      this.$emit("codAnnulé", codSelectionne);
    },

    incrementeEtape2(listeCodRegroupe) {
      this.listeCodRegroupe = listeCodRegroupe;
      this.etape += 1;
    },

    // Etape 2 - Coi
    coiValide(coiSelectionne) {
      this.$emit("coiValidé", coiSelectionne);
    },

    coiAnnule(coiSelectionne) {
      this.$emit("coiAnnulé", coiSelectionne);
    },

    incrementeEtape3(listeCoiRegroupe) {
      this.listeCodRegroupe = listeCoiRegroupe;
      this.etape += 1;
    },

    // Etape 4 - Compléments circonstanciels

    complementValide(texteSelectionne) {
      console.log("Complément : ", texteSelectionne);
      this.$emit("complémentValidé", texteSelectionne);
    },

    complementAnnule(texteSelectionne) {
      this.$emit("complémentAnnulé", texteSelectionne);
    },

    incrementeEtape4() {
      this.etape += 1;
    },
  },
};
</script>

<style scoped>
h2 {
  margin-left: 1rem;
}

.container-secondaire {
  max-width: 100rem;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  padding: 2rem;
  border: 2px solid #ccc;
  border-radius: 12px;
  position: relative;
}
</style>
