<template>
  <h2>Récapitulatif</h2>

  <div>
    <h3>Sélection des verbes</h3>

    <ul>
      <li
        v-for="verbe in verbesSelectionnes"
        :key="verbe"
        class="verbe"
      >
        {{ verbe }}
      </li>
    </ul>

    Le verbe principal est «<span class="verbe">{{ getVerbePrincipal() }}</span>».
  </div>

  <div>
    <h3>Remplacement</h3>

    <span
      v-for="mot in listeMotsRegroupes"
      :key="mot"
      :class="{verbe: this.verbesSelectionnes.includes(mot)}"
    >
      {{ motAffiche(mot) }}
    </span>
  </div>

  <div>
  <h3>Négation</h3>

  <span
      v-for="mot in listeMotsRegroupes"
      :key="mot"
      :class="{verbe: this.verbesSelectionnes.includes(mot)}"
    >
      {{ motAfficheNegation(mot) }}
    </span>
  </div>

  <div>
  <h3>Insertion</h3>

    <p><span class="souligne">Passé</span> {{ getPhraseHier() }}</p>

    <p><span class="souligne">Présent</span> {{ getPhraseAuj() }}</p>
    
    <p><span class="souligne">Futur</span> {{ getPhraseDemain() }}</p>

  </div>
</template>

<script>
export default{
    inject: ['verbesSelectionnes', 'listeMotsRegroupes', 'getVerbesValues', 'getVerbesValuesNegation', 'getPhraseHier', 'getPhraseAuj', 'getPhraseDemain', 'getVerbePrincipal'],

    methods: {
    motAffiche(mot) {
      if (mot === " ") {
        return "\u00a0";
      } else if (this.verbesSelectionnes.includes(mot)) {
        return this.getVerbesValues()[mot] || "____";
      } else {
        return mot;
      }
    },
    motAfficheNegation(mot) {
      if (mot === " ") {
        return "\u00a0";
      } else if (this.verbesSelectionnes.includes(mot)) {
        return this.getVerbesValuesNegation()[mot] || "____";
      } else {
        return mot;
      }
    },
}
}
</script>

<style scoped>
.verbe {
  color: blue;
}

label{
    float: left;
    clear: left;
    width: 60px;
    text-align: right;
    margin: 1rem;
}
input {
  float: left;
  margin: 1rem;
  width: 50%;
}

.souligne{
  text-decoration: underline;
}
</style>
